import React from "react";
import "./SoldOut.scss"; // Import the CSS file

const SoldOut = () => {
  return (
    
    <div className=" grow flex items-center justify-center ">
      <img
        className="fadeIn"
        src="../../../../images/logo-ecai.svg"
        width="290"
        height="84"
        alt="ECAI-LOGO"
        id="ecmlLogo"
      />
     
    </div>
    
  );
};

export default SoldOut;
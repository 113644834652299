import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import Spinner from "../components/Spinner/Spinner";
import Registrations from "../admin-pages/Registrations/Registrations";
import RegistrationsViewer from "../admin-pages/RegistrationsViewer/RegistrationsViewer";
import Invoices from "../admin-pages/Invoices/Invoices";
import Dashboard from "../admin-pages/Dashboard/Dashboard";
import Codes from "../admin-pages/Codes/Codes";
import Papers from "../admin-pages/Papers/Papers";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import {
  Barcode,
  LayoutDashboard,
  User2,
  Layers,
  ReceiptEuro,
  NotebookText,
} from "lucide-react";

const MenuAdmin = ({ handler }) => {
  const { adminRole } = useContext(RegistrationContext);

  if (adminRole === "viewer") {
    return (
      <div className="flex flex-col gap-4 mt-2">

        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-viewer-registrations")}
        >
          <User2 size={18} />
          Registrations
        </div>
      </div>
    );
  }

  if (adminRole === "admin") {
    return (
      <div className="flex flex-col gap-4 mt-2">
        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-dashboard")}
        >
          <LayoutDashboard size={18} />
          Dashboard
        </div>
        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-registrations")}
        >
          <User2 size={18} />
          Registrations
        </div>
        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-invoices")}
        >
          <ReceiptEuro size={18} />
          Invoices
        </div>
        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-codes")}
        >
          <Barcode size={18} /> Codes
        </div>

        <div
          className="flex gap-4 cursor-pointer items-center"
          onClick={() => handler("admin-papers")}
        >
          <NotebookText size={18} /> Papers
        </div>
        {/*
      <div
        className="flex gap-4 cursor-pointer items-center"
        onClick={() => handler("admin-papers")}
      >
        <Layers size={18} /> Papers
      </div> */}
      </div>
    );
  }
  return <>No menu entries for: {adminRole}</>;
};

const AdminUser = () => {
  const { screen, setScreen, loading, adminRole } =
    useContext(RegistrationContext);

  /* TODO */
  return (
    <>
      <div
        className="maincnt h-full border border-t-gray-200"
        style={{ backgroundColor: "white" }}
      >
        {loading ? (
          <div className="flex justify-center h-full items-center">
            <Spinner />
          </div>
        ) : (
          <div className="font-bold flex h-full">
            <div
              className="px-8 py-2 text-ecmlBrown border-0 border-r-ecmlOrange border-t-0 text-white z-30 fixed h-full"
              style={{ backgroundColor: "#2e96ff" }}
            >
              <MenuAdmin handler={setScreen} />
            </div>
            <div
              className="bg-white w-full h-full grow"
              style={{ paddingLeft: "12.7rem" }}
            >
              {screen === "admin-dashboard" && (
                <AdminLayout title="Dashboard" icon={<LayoutDashboard />}>
                  <Dashboard />
                </AdminLayout>
              )}
              {screen === "admin-registrations" && (
                <AdminLayout title="Registrations" icon={<User2 />}>
                  <Registrations />
                </AdminLayout>
              )}
              {screen === "admin-viewer-registrations" && (
                <AdminLayout title="Registrations" icon={<User2 />}>
                  <RegistrationsViewer />
                </AdminLayout>
              )}
              {screen === "admin-invoices" && (
                <AdminLayout title="Invoices" icon={<ReceiptEuro />}>
                  <Invoices />
                </AdminLayout>
              )}
              {screen === "admin-codes" && (
                <AdminLayout title="Codes" icon={<Barcode />}>
                  <Codes />
                </AdminLayout>
              )}
              {screen === "admin-papers" && (
                <AdminLayout title="Papers" icon={<NotebookText />}>
                  <Papers />
                </AdminLayout>
              )}
              {/*

              {screen === "admin-papers" && (
                <AdminLayout title="Papers" icon={<Layers />}>
                  <Papers />
                </AdminLayout>
              )} */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminUser;

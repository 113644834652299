import { useId, useContext, useEffect, useMemo, useState } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { ChefHat, Handshake, Mails, Printer, Wine } from "lucide-react";
import { RiFileExcel2Line } from "@remixicon/react";
import { base_url } from "../../config";
import axios from "axios";
import { Box, MenuItem, Tooltip } from "@mui/material";
import RegistrationContext from "../../contexts/RegistrationContext";
import SidePanel from "../../components/SidePanel/SidePanel";
import { User } from "lucide-react";
import "./style.css";
import workshops from "../../util/workshops.json";
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}



export default function App() {
  const { setError, reload, setReload } = useContext(RegistrationContext);
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //simple recommended way to define a column
        header: "ID",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, 
        size: 20,//disable a feature for this column
      },
      {
        accessorKey: "registration_date", //simple recommended way to define a column
        header: "Registration Date",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: true, //disable a feature for this column
      },
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Name",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },

 

      {
        accessorKey: "student", //simple recommended way to define a column
        header: "Student",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, 
        size:20//disable a feature for this column
      },

      {
        accessorKey: "eurai", //simple recommended way to define a column
        header: "EurAI Member",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, 
        size:20//disable a feature for this column
      },
      {
        accessorKey: "camino", //simple recommended way to define a column
        header: "Camino",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, //disable a feature for this column
        size: 20,
      },
      {
        accessorKey: "reception", //simple recommended way to define a column
        header: "Reception",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, //disable a feature for this column
        size:20
      },
      {
        accessorKey: "gala_dinner", //simple recommended way to define a column
        header: "Gala Dinner",    
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: true, //disable a feature for this column
      },
      
      {
        accessorKey: "additional_gala_dinner", //simple recommended way to define a column
        header: "Extra Dinner",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, //disable a feature for this column
        size:20
      },
      {
        accessorKey: "access",
        header: "Access",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } },
        enableHiding: false,
      },

      {
        accessorKey: "weekend_access",
        header: "Weekend Access",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } },
        enableHiding: false,
      },
      // New weekdays column
      {
        accessorKey: "weekdays_access",
        header: "Weekdays Access",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } },
        enableHiding: false,
      },

      {
        accessorKey: "affiliation", //simple recommended way to define a column
        header: "Affiliation",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, 
        enableHiding: true, 
        size: 20,
    
      },
      {
        accessorKey: "affiliation2", //simple recommended way to define a column
        header: "Affiliation2",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: true, //disable a feature for this column
      },

      {
        accessorKey: "email", //simple recommended way to define a column
        header: "eMail",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "special_requirements", //simple recommended way to define a column
        header: "Special Req",
        muiTableHeadCellProps: { style: { color: "black", fontSize:"12px" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
    ],
    []
  );

  const [details, setDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});




  useEffect(() => {
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/registrations`);
      const filteredData = comm.data.filter((i) => i.state === "completed");

      // Sort by registration_date in ascending order
      filteredData.sort((a, b) => new Date(a.registration_date) - new Date(b.registration_date));
  
      setData(filteredData);
      setOriginalData(filteredData);
    };

    loadData();
  }, []);

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)

    enableRowSelection: false, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    initialState: {
      showColumnFilters: true,
      density: "compact",
      pagination: {
        pageSize: 15, // Show all rows initially
      },
    },

    // enableRowActions: true,
    // positionActionsColumn: "last",
    // renderRowActionMenuItems: ({ closeMenu, row }) => [
    //   <MenuItem key="view" onClick={() => handleView(closeMenu, row)}>
    //     View
    //   </MenuItem>,
    //   <MenuItem key="impersonate" onClick={() => handleImpersonate(row)}>
    //     Impersonate
    //   </MenuItem>,
    // ],
    muiTableHeadCellProps: {
      // simple styling with the `sx` prop, works just like a style prop in this example
      // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
      sx: {
        backgroundColor: "#e3f1ff",
      },
    },
    renderTopToolbarCustomActions: ({table}) => (
 
      <div
      className=" flex flex-end cursor-pointer"
      onClick={() => handleExport(table)}
    >
      {" "}
      <RiFileExcel2Line className="text-green-800" /> Export filtered data
    </div>
    ),
  });


  const handleExport = (table) => {
    // Get the filtered rows from the table
    const filteredData = table.getFilteredRowModel().rows.map(row => row.original);

    // Prepare data for export
    const exportData = filteredData.map(row => ({
      ID: row.id,
      "Registration Date": row.registration_date,
      Name: row.name,

      Student: row.student,
      "EurAI Member": row.eurai,
      Camino: row.camino,
      Reception: row.reception,
      Gala: row.gala_dinner,
      "Extra Dinner": row.additional_gala_dinner,
      "Access": row.access,
      "Weekend Access": row.weekend_access,
      "Weekdays Access": row.weekdays_access,
      Affiliation: row.affiliation,
      Affiliation2: row.affiliation2,
      eMail: row.email,
      "Special Req": row.special_requirements,
    }));

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Data');

    const now = new Date();
    const options = {
      timeZone: 'Europe/Madrid', // Spain's timezone
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    
    const formattedDate = new Intl.DateTimeFormat('sv-SE', options).format(now).replace(/ /g, '_').replace(/:/g, '_');


    // Export to Excel with the formatted date in the filename
    const filename = `filtered_table_data_${formattedDate}.xlsx`;
    XLSX.writeFile(workbook, filename);

  };

  return (
    <>
      <div className="flex flex-col v-full h-full ">
        <div>
  
          <MaterialReactTable table={table} />
        </div>
      </div>
    
    </>
  );
}

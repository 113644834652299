import {
  ChefHat,
  Contact,
  Church,
  HandCoins,
  Mails,
  Printer,
  Ticket,
  Wine,
} from "lucide-react";
import { BarChart, PieChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../config";

const NumberBox = ({ Icon = null, bigletters, title }) => {
  return (
    <div className="w-1/4">
      <div className="flex flex-row  shadow-lg p-6 rounded-lg w-full bg-slate-100">
        <div className="w-20  text-blueAdmin">{Icon && <Icon size={48} />}</div>
        <div className="flex flex-col gap-4 w-full">
          <div
            className="text-slate-900 text-right"
            style={{ fontSize: "2rem", lineHeight: "0.7" }}
          >
            {bigletters}
          </div>
          <div className="text-slate-700 font-thin text-right">{title}</div>
        </div>
      </div>
    </div>
  );
};

const ChartBox = ({ data, title }) => {
  if (!data) return null;
  return (
    <div className="w-1/2">
      <div className="flex flex-col items-center justify-center bg-slate-100 shadow-lg p-6 rounded-lg w-full">
        <div className="text-xl mb-4">{title}</div>
        <div className="flex w-full justify-center">
          <PieChart
            series={[
              {
                data,
              },
            ]}
            height={250}
          />
        </div>
      </div>
    </div>
  );
};

const BarChartBox = ({ data, title, label, seriesId }) => {
  const sortedDataRegistrations = data.sort((a, b) => {
    return a.date > b.date ? 1 : -1;
  });
  const numberOfRegistrations = sortedDataRegistrations.map(
    (data) => data.value
  );
  const dateLabels = sortedDataRegistrations.map((data) => data.date);

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center bg-slate-100 shadow-lg p-6 rounded-lg w-full">
        <div className="text-xl mb-4">{title}</div>
        <div className="flex w-full justify-center">
          <BarChart
            height={250}
            series={[
              {
                data: numberOfRegistrations,
                label: label,
                id: seriesId,
              },
            ]}
            xAxis={[{ data: dateLabels, scaleType: "band" }]}
          />
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/stats`);
      //const data = await response.json();
      setData(comm.data);
      setLoading(false);
    };

    loadData();
  }, [reload]);

  if (loading) return <div>Loading...</div>;

  /*
    This array of objects will come from the backend within data.registrations, for example.
    The backend will provide me with an array of objects where the date is not repeated. Each object 
    will have the total number of registrations on a given day.
  */

  return (
    <div
      className="flex flex-col gap-4 bg-white"
      style={{ minWidth: "1280px", maxWidth: "1600px" }}
    >

      <div className="flex gap-4">
        <NumberBox
          Icon={Ticket}
          bigletters={data.ticket}
          title="Registrations"
        />
        <NumberBox
          Icon={ChefHat}
          bigletters={data.gala_dinner}
          title="Gala dinner tickets"
        />
        <NumberBox
          Icon={Wine}
          bigletters={data.reception}
          title="Reception event"
        />
        <NumberBox
          Icon={Church}
          bigletters={data.pilgrims}
          title="Pilgrims "
        />
        <NumberBox
          Icon={HandCoins}
          bigletters={data.income + " €"}
          title="Income"
        />

      </div>
      <div className="flex gap-4">



      </div>
      <div className="flex gap-4">
        <ChartBox title="Delegate Types" data={data.types} />
        <ChartBox title="Registration Access" data={data.access} />
        <ChartBox title="Registration Progress" data={data.progress} />
      </div>

      <div className="flex gap-4">
        <BarChartBox
          title="Registrations per day"
          data={data.dataRegistrations}
          seriesId="registrationsId"
          label="number of registrations"
        />
      </div>
    </div>
  );
};

export default Dashboard;

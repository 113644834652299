// src/contexts/RegistrationContext.js

import React from "react";

const RegistrationContext = React.createContext({
  screen: "loading", // default value
  setScreen: () => {}, // default empty function
  authenticated: false,
  setAuthenticated: () => {},
  error: "",
  setError: () => {},
  status: "",
  setStatus: () => {},
  reload: false,
  setReload: () => {},
  loading: false,
  setLoading: () => {},
  maxStep: "",
  setMaxStep: () => {},
  data: {},
  setData: () => {},
  cartOpen: false,
  setCartOpen: () => {},
  cart: {},
  setCart: () => {},
  cartAdded: false,
  setCartAdded: () => {},
  userInfoOpen: false,
  setUserInfoOpen: () => {},
  email: "",
  setEmail: () => {},
  phase: "",
  setPhase: () => {},
  inCommunication: false,
  setInCommunication: () => {},
  paperOpen: false,
  setPaperOpen: () => {},
  paperSelected: [],
  setPaperSelected: () => {},
  paperSelectedVerified: [],
  setPaperSelectedVerified: () => {},
  isAdmin: false,
  setIsAdmin: () => {},
  adminRole: "",
  setAdminRole: () => {},
  isImpersonating: false,
  setIsImpersonating: () => {},
  isStudent: false,
  setIsStudent: () => {},
  isMainAccess: false,
  setIsMainAccess: () => {},
  optionAccess: "",
  setOptionAccess: () => {},
  clickedNext: 0,
  setClickedNext: () => {},
  next: null,
  setNext: () => {},
  prices: {},
  setPrices: () => {},
  highlightedCart: false,
  setHighlightedCart: () => {},
  extraPages: {},
  setExtraPages: () => {},
  upgradableOptions: [],
  setUpgradableOptions: () => {},
  isSoldout: false,
  setIsSoldout: () => {},
});

export default RegistrationContext;
